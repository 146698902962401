const Comment = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3 16C6.06131 16 5.83239 15.9157 5.6636 15.7657C5.49482 15.6157 5.4 15.4122 5.4 15.2V12.8H1.8C1.32261 12.8 0.864773 12.6314 0.527208 12.3314C0.189642 12.0313 0 11.6243 0 11.2V1.6C0 0.712 0.81 0 1.8 0H16.2C16.6774 0 17.1352 0.168571 17.4728 0.468629C17.8104 0.768687 18 1.17565 18 1.6V11.2C18 11.6243 17.8104 12.0313 17.4728 12.3314C17.1352 12.6314 16.6774 12.8 16.2 12.8H10.71L7.38 15.768C7.2 15.92 6.975 16 6.75 16H6.3ZM7.2 11.2V13.664L9.972 11.2H16.2V1.6H1.8V11.2H7.2ZM3.6 4H14.4V5.6H3.6V4ZM3.6 7.2H11.7V8.8H3.6V7.2Z"
        fill="#DDDDDD"
      />
    </svg>
  );
};

export default Comment;
