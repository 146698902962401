interface Props {
  isActive?: boolean;
}

const Mokoko = ({ isActive }: Props) => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.74446 8.32004C5.00549 8.21032 6.52641 8.46392 8.6033 7.13019C10.0869 5.95982 10.613 5.44811 11.0873 5.35757C11.2322 5.31328 11.45 5.27853 11.6817 5.2971C12.024 5.32454 12.328 5.51422 12.6088 5.86963C13.0003 6.36495 13.0003 6.7226 12.9797 7.7349C12.954 9.00391 12.275 10.295 11.7558 11.3191C11.0635 12.4529 8.86329 15.0613 7.00014 15.3063C4.77479 15.5988 3.55904 15.1228 3.22525 14.9765C3.05213 14.9007 2.39136 14.5666 1.82376 13.8793C1.37071 13.3307 0.999974 12.3066 1 11.5751C1.00003 10.6974 1.03698 10.3316 1.6675 9.41726C2.29802 8.50295 3.03976 8.42976 3.74446 8.32004Z"
        fill={isActive ? "#D0E190" : "#DDD"}
        stroke={isActive ? "#D0E190" : "#DDD"}
        strokeWidth="2"
      />
      <path
        d="M10.8821 3.86169C10.6274 3.68728 7.35264 3.14661 5.58789 2.78896C6.29379 2.43131 8.54248 1.02837 9.82329 1.00047C11.1041 0.972563 11.8282 2.19471 12.2648 3.89693C12.4831 3.17604 13.2764 1.73427 14.7027 1.73427C15.576 1.65288 18.2941 2.07366 19 3.14638C18.2941 3.14661 16.1764 3.50404 14.8483 3.96672C13.9386 4.35042 13.1381 4.58762 12.4104 5.25734V5.29222C12.3936 5.29222 12.3786 5.27989 12.3652 5.25734L11.9373 4.59459C11.7052 4.17728 11.1282 3.95605 10.8844 3.86254L10.8821 3.86169Z"
        fill={isActive ? "#54AD56" : "#DDD"}
      />
      <path
        d="M12.2648 3.89693C11.8282 2.19471 11.1041 0.972563 9.82329 1.00047C8.54248 1.02837 6.29379 2.43131 5.58789 2.78896C7.35264 3.14661 10.6274 3.68728 10.8821 3.86169C11.1247 3.95471 11.7045 4.17601 11.9373 4.59459L12.3652 5.25734M12.2648 3.89693C12.4831 3.17604 13.2764 1.73427 14.7027 1.73427C15.576 1.65288 18.2941 2.07366 19 3.14638C18.2941 3.14661 16.1764 3.50404 14.8483 3.96672C13.9386 4.35042 13.1381 4.58762 12.4104 5.25734V5.29222C12.3936 5.29222 12.3786 5.27989 12.3652 5.25734M12.2648 3.89693C12.2541 4.30846 12.2624 5.08414 12.3652 5.25734"
        stroke={isActive ? "#54AD56" : "#DDD"}
        strokeWidth="1.8"
      />
    </svg>
  );
};

export default Mokoko;
